import { SpeedInsights } from '@vercel/speed-insights/react';

import PlayerBanner from "../components/banner";
import Footer from "../components/footer";
import PlayerCard from "../components/player";

import "../index.css";

function App() {
	const players = [
		{
			name: "Vibe Vipin",
			age: "22",
			position: "4 Line",
			role: "Batsman",
			image: "assets/images/players/vipin.avif",
		},
		{
			name: "Sharpu Sathish",
			age: "28",
			position: "4 Line",
			role: "Batsman",
			image: "assets/images/players/sathish.avif",
		},
		{
			name: "Moratu Adi Muthu",
			age: "23",
			position: "Slip",
			role: "Batsman",
			image: "assets/images/players/muthaiyan.avif",
		},
		{
			name: "Lordu Loki",
			age: "22",
			position: "Middle",
			role: "Bowler",
			image: "assets/images/players/loki.avif",
		},
		{
			name: "Rugged Renga",
			age: "24",
			position: "Slip",
			role: "Undefined",
			image: "assets/images/players/renga.avif",
		},
		{
			name: "Gold Star Guru",
			age: "22",
			position: "WK",
			role: "Batsman",
			image: "assets/images/players/guru.avif",
		},
		{
			name: "Notorious Navaneeth",
			age: "27",
			position: "Middle",
			role: "Bowler",
			image: "assets/images/players/navaneeth.avif",
		},
	];



	return (
		<div>
			<SpeedInsights />
			<img className="100vh" src="assets/images/banner.avif" alt="hero banner" />
			<div className="flex items-center justify-center mt-10">
				<section className="mb-20 grid lg:grid-cols-4 grid-cols-2 gap-8 gap-y-20">
					{players.map((player, index) => (
						<PlayerCard key={index} player={player} />
					))}
				</section>
			</div>
			<PlayerBanner />
			<img className="p-4 md:p-20" src="assets/images/banner2.avif" alt="banner-2" />
			<Footer />



		</div>
	);
}

export default App;